import ProgressStep from '@/shared/classes/progress/progress-step'
import _ from 'lodash'

export default class ProgressBase {
  steps: ProgressStep[] = null!
  current!: string
  active!: string
  activeId!: number
  isValid = false
  initialised = false
  allowLinks = false

  setSteps(steps: ProgressStep[]): this {
    this.steps = steps.map((step: ProgressStep, index: number) => step.setIndex(index))

    return this
  }

  setCurrent(current: string): this {
    this.current = current

    return this
  }

  setActive(active: string): this {
    this.active = active

    return this
  }

  setActiveId(activeId: number): this {
    this.activeId = activeId

    return this
  }

  setActiveAndCurrent(key: string): this {
    this.setActive(key)
    this.setCurrent(key)

    return this
  }

  setCompleteSteps(key: string): this {
    for (let index: any = 0; index < this.steps.length; index++) {
      if (!this.steps[index]) return this
      this.steps[index].isDone()

      if (this.steps[index].key === key) break
    }

    return this
  }

  setCurrentStep(key: number): this {
    if (this.steps[key]) {
      this.steps[key].isCurrent()
    }

    return this
  }

  setAllowLinks(allowLinks: boolean): this {
    this.allowLinks = allowLinks

    return this
  }

  setInitialised(initialised: boolean): this {
    this.initialised = initialised

    return this
  }

  reset() {
    this.steps.forEach((step: any) => {
      step.done = false
    })
    this.current = this.steps[0].key
    this.active = this.steps[0].key
    this.initialised = false
  }

  next() {
    const activeIndex = this.steps.findIndex((step: ProgressStep) => step.key === this.active)

    const currentIndex = this.steps.findIndex((step: ProgressStep) => step.key === this.current)

    const nextCurrentIndex = currentIndex + 1

    const nextCurrentStep = this.steps[nextCurrentIndex]

    if (nextCurrentIndex > activeIndex) this.setActive(nextCurrentStep.key)
    this.setStepDone(this.steps[activeIndex])

    return nextCurrentStep.key
  }

  previous() {
    const currentIndex = this.steps.findIndex((step: ProgressStep) => step.key === this.current)

    const previousIndex = currentIndex - 1

    return this.steps[previousIndex].key
  }

  setStepDone(step: ProgressStep) {
    step.done = true
  }

  setIsValid(isValid: boolean) {
    this.isValid = isValid
  }

  get totalSteps(): number {
    return this.steps.length
  }

  get currentStep(): number {
    return this.steps.findIndex((step: ProgressStep) => step.key === this.current) + 1
  }

  get currentStepObject(): ProgressStep | undefined {
    return _.find(this.steps, (step: ProgressStep) => step.key === this.active)
  }
}
