var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sticky)?_c('div',{directives:[{name:"stick-in-parent",rawName:"v-stick-in-parent",value:(_vm.stikyKit),expression:"stikyKit"}],staticClass:"progress",class:_vm.wrapperClass},[_vm._l((_vm.progress.steps),function(step,index){return _c('div',{key:step.key,class:{
      progress__segment: true,
      'progress__segment--edge': index === 0,
      'progress__segment--done': step.done,
      'progress__segment--active': step.key === _vm.progress.active,
      'progress__segment--flag': step.key === _vm.progress.current,
    }},[_c(_vm.active(step),{tag:"a",class:{
        'cursor-pointer': _vm.allowCursor(step),
      },domProps:{"textContent":_vm._s(step.title)},on:{"click":function($event){return _vm.changeStepByLink(step, index)}}})],1)}),_c('div',{staticClass:"progress__segment progress__segment--edge"})],2):_c('div',{staticClass:"progress",class:_vm.wrapperClass},[_vm._l((_vm.progress.steps),function(step,index){return _c('div',{key:step.key,class:{
      progress__segment: true,
      'progress__segment--edge': index === 0,
      'progress__segment--done': step.done,
      'progress__segment--active': step.key === _vm.progress.active,
      'progress__segment--flag': step.key === _vm.progress.current,
    }},[_c(_vm.active(step),{tag:"a",class:{
        'cursor-pointer': _vm.allowCursor(step),
      },domProps:{"textContent":_vm._s(step.title)},on:{"click":function($event){return _vm.changeStepByLink(step, index)}}})],1)}),_c('div',{staticClass:"progress__segment progress__segment--edge"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }