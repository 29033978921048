export default class ProgressStep {
  key: string = null!
  title: string = null!
  done = false
  current!: boolean
  index!: number

  setKey(key: string): this {
    this.key = key

    return this
  }

  setTitle(title: string): this {
    this.title = title

    return this
  }

  isDone(): this {
    this.done = true

    return this
  }

  isCurrent(): this {
    this.current = true

    return this
  }

  setIndex(index: number): this {
    this.index = index

    return this
  }
}
