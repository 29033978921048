import Phase from '@/shared/interfaces/proposal/phase'
import { StartConditionOptions } from '@/shared/configs/phase-start-options'
import _ from 'lodash'
import SelectOption from '@/shared/classes/form/select-option'

export default class ProposalPhase implements Phase {
  active?: boolean
  uuid: string
  name: string
  description: string
  start_condition: any[]
  required_materials: string
  planned_start_at: string
  planned_end_at: string
  revisions: number
  revision_timeline: number
  revision_time_unit: string
  cost: number
  currency: string
  index: number
  min_cost: number
  max_cost: number
  started_at: string | null
  dynamic_permissions: any

  constructor(data: Phase) {
    this.active = data?.active
    this.uuid = data.uuid
    this.name = data.name
    this.description = data.description
    this.start_condition = this.setStartConditions(data)
    this.required_materials = data.required_materials
    this.planned_start_at = data.planned_start_at
    this.planned_end_at = data.planned_end_at
    this.revisions = data.revisions
    this.revision_timeline = data.revision_timeline
    this.revision_time_unit = data.revision_time_unit
    this.cost = data.cost
    this.currency = data.currency
    this.index = data.index || 0
    this.min_cost = data.min_cost || 0
    this.max_cost = data.max_cost || 0
    this.started_at = data?.started_at || null
    this.dynamic_permissions = data.dynamic_permissions
  }

  private setStartConditions(data: Phase) {
    if (data.start_condition.length) {
      const processedConditions: SelectOption[] = []
      data.start_condition.forEach((conditionKey) => {
        const filteredCondition = _.find(StartConditionOptions, (condition) => condition.key === conditionKey)

        if (filteredCondition) {
          processedConditions.push(filteredCondition)
        }
      })

      return processedConditions
    }

    return [StartConditionOptions[0]]
  }

  public payload(): Phase {
    const start_condition: string[] = []
    this.start_condition.forEach((condition) => {
      start_condition.push(condition.key)
    })

    return {
      index: this.index,
      uuid: this.uuid,
      name: this.name,
      description: this.description,
      start_condition,
      required_materials: this.required_materials,
      planned_start_at: this.planned_start_at,
      planned_end_at: this.planned_end_at,
      revisions: this.revisions,
      revision_timeline: this.revision_timeline,
      revision_time_unit: this.revision_time_unit,
      cost: this.cost,
      currency: this.currency,
    }
  }
}
